import React from 'react'

const Card = ({ commessa, stato, articolo, produzione, volumeTotale }) => {

  const cardColor = ({stato}) => {
    {if (stato === "Attivo") {
      return ' bg-green-100 rounded-2xl my-5 p-5 flex-wrap shadow'
    } else {
      return 'bg-red-100 rounded-2xl my-5 p-5 flex-wrap shadow'
    }}
  };
  
  return (
    <div className = {cardColor({stato})}>
    <p className='font-semibold text-xl'>{commessa}</p>
    <p>Stato macchina: {stato}</p>
    <p>Articolo: {articolo}</p>
    <p>Produzione: {produzione}</p>
    <p>Volume totale: {volumeTotale}</p>
  </div>
  )
}

export default Card