import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import logoForge from './logo_FORGETRONIC_rgb.png'
import Card from './Card'

function DynamicPage({ page }) {

  const [data, setData] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [fileContents, setFileContents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Funzione per caricare i dati
    const fetchData = async () => {
      try {
        const responseFiles = await axios.get(`/${page.path}/CM.json`);
        const files = responseFiles.data
        //console.log(files)
        setFileNames(files)
        
        //const allData = await Promise.all([
         // files.map(async (fileName) => {
            //const fileData = await axios.get(`/abc/CM0124.json`);
          //  const fileData = await axios.get(`/${page.path}/${fileName.commessa}.json`);
           // console.log(fileData.data)
            //return fileData.data;
         // })
       // ]);

        const fileRequests = files.map(file => axios.get(`/${page.path}/${file.commessa}.json`));
        const fileResponses = await Promise.all(fileRequests);
        // Concatena i dati in un unico array (o oggetto, a seconda della struttura)
        //setData(allData.flat());
        //console.log(fileResponses)

        const lista = fileResponses.map (response => response.data[0])
        setFileContents(lista);
        //console.log(lista)
        setLoading(false);

      } catch (error) {
        console.error('Errore durante il caricamento dei dati:', error);
        setLoading(false);
      }
    };
    
    // Carica i dati una volta all'avvio
    fetchData();

    // Imposta un intervallo per caricare i dati ogni 20 secondi
    const intervalId = setInterval(fetchData, 20000);

    // Pulisce l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <div>Caricamento...</div>;
  }

  return (
    <div className="max-w-md mx-auto bg-gray-50 rounded-xl shadow-md overflow-hidden md:max-w-2xl p-3">
      <div className=' bg-white rounded-2xl p-10 flex-auto '>
      <img src={logoForge} className='my-2'></img>
      <p className='text-gray-500 font-thin'>App di monitoraggio</p>
      </div>
      <div className="my-5">
        {fileContents.length > 0 ? (
          <ul>
            {fileContents.map((item,key) => (
              <Card commessa={item.commessa} stato = {item.stato} articolo = {item.articolo} produzione = "3640 pz/h" volumeTotale = {`${item.volume} pz/h`}/>
            ))}
          </ul>
        ) : (
          <p>Caricamentoujmjgyuk...</p>
        )}
      </div>
    </div>
  );
}

export default DynamicPage;